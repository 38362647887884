<template>
    <div class="stat">
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="value">
        <span>{{ stat }}</span>
        <img v-if="img" :src="img" alt="">
      </div>
        
    </div>
  </template>
  
  <script>
  export default {
    name: 'settingsProfileStat',
    data() {
      return {
        // Your data properties go here
      };
    },
    methods: {
      // Your methods go here
    },
    props: {
        stat: String,
        title: String,
        img: String
    }
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
    .stat {
        color: beige;
        font-family: 'Rubik', sans-serif;
        display: flex;
        font-weight: 400;
        margin: auto 0;
        flex-direction: column;
    }

    .value > img {
      width: 18px;
      height: 18px;
      margin: auto 0 auto 4px;
    }

    .title {
      color: #C02942;
    }

    .value {
      display: flex;
      font-size: 18px;
    }

    
  </style>
  