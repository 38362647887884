<!-- Navbar.vue -->

<template>
    <div class="game">
      <router-link :to="gameLink">
        <img :src="getImage(icon)" alt="">
        <span>{{ game }}</span>
      </router-link>
    </div>   
</template>

<script>
export default {
  name: 'gameLink',
  components: {

},
data() {
  return {
  }
},
methods: {
  getImage(img) {
    return require(`@/assets/gameIcon/${img}`)
  }
},
props: {
    game: String,
    icon: String,
    activeTabCheck: String,
    gameLink: String
},
computed: {
  // Use a computed property to access the current route path
  currentRoutePath() {
    return this.$route.path;
  }
}}

</script>

<style scoped>
/* Add your styling here */


.game > a {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
}

.game > a > img {
    width: 40px;
    height: 40px;
    margin: 2px auto;
}

.game > a > span {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-top: 4px;



}

.game > a > span:visited {
  color: inherit;
}

.active {
    border-left: 3px solid #3d5cfa;
    margin-right: auto;
}



</style>
