<template>
  <div class="gamestab">
    <div class = PopularNow>
      <span>Popular Right Now</span>
      <div class="navButtons" :class="{ 'visible': isOverflowed, 'invisible': !isOverflowed}">
        <button @click="scrollToLeft">
          &lt;
        </button>
        <button @click="scrollToRight">
          &gt;
        </button>
      </div>
      
    </div>
    <div class = "PopularBorder">

      <div class="games" ref="scrollContainer">
        <div class="game">
          <gameComponent :img="getImage('gameIcon/crash.png')" gameName="Crash" />
        </div>
        <div class="game">
          <gameComponent :img="getImage('gameIcon/crash.png')" gameName="Slide" />
        </div>
        <div class="game">
          <gameComponent :img="getImage('gameIcon/crash.png')" gameName="Mines" />
        </div>
        <div class="game">
          <gameComponent :img="getImage('gameIcon/crash.png')" gameName="Towers" />
        </div>
        
        
      </div>

    </div>
    
  </div>
    
  </template>
  
  <script>
  import gameComponent from './gameComponent.vue'
  export default {
    name: 'gamesTab',
    data() {
      return {
        isOverflowed: false
      };
    },
    mounted() {
      this.checkOverflow();
      window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      getImage(img) {
        return require(`@/assets/${img}`)
      },
      scrollToNewElements() {
        const container = this.$refds.scrollContainer;
        container.scrollLeft += (160+10)*2 // Adjust as needed
      },
      checkOverflow() {
        const container = this.$refs.scrollContainer;
        this.isOverflowed = container.scrollWidth > container.clientWidth;
      },
      handleResize() {
        this.checkOverflow();
      },
      scrollToLeft() {
        const container = this.$refs.scrollContainer;
        container.scrollRight += (+160+10)*2 // Adjust as needed
      },
      scrollToRight() {
        const container = this.$refs.scrollContainer;
        container.scrollLeft += (160+10)*2 // Adjust as needed
      },
    },
    components: {
      gameComponent
    }
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
  .PopularNow{
    color: white;
    font-size: 30px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    display: flex;

  }

  .PopularNow 
  .PopularBorder{

    display: inline-flex;
    margin: 0 auto;
  }

  .game {
    background-color: #282e54;
    border-radius: 16px;

  }

  .games {
    display: flex;
    color: #5f6892;
    gap: 25px;
    align-items: center;
    align-items: center;
    width: calc(100vw - 200px); /* Adjust the margin value as needed */
    overflow-y: auto;
    padding: 16px 0;

 
  }

  .navButtons {
    display: flex;
    margin-left: auto;
  }

  .navButtons > button {
    height: 36px;
    width: 36px;
    margin-right: 6px;
    appearance: none;
    outline: none;
    border: none;
    background-color: #3656ff;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    color: beige;
    font-size: 20px;
    box-shadow: 3px 3px 2px 1px #000;
  }

  .invisible {
    display: none;
  }

  </style>
  