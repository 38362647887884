<template>
  <div>
    <div class="profile">
      <div class="balance">
        <span id="balance-text">Balance</span>
        <profileStats :stat="balance.toLocaleString()" :img="getImage('currency.png')" class="stat-text" />
      </div>
      <div class="buttons">
        <navElementButton class="wallet-button" name="Wallet" @button-click="switchWallet"/>
        <router-link to="/settings">
          <navElementButton class="settings-button" name="⚙"/>
        </router-link>
        
      </div>
      

    </div>




    <modalComponent @switchRbx="openSubmodalRbx" @switchCrypto="openSubmodalCrypto" @switchModal="switchWallet" :isMainActive=isMainActive :isCryptoActive=isCryptoActive :isRBXActive=isRBXActive></modalComponent>
  </div>
   
  </template>
  
  <script>
  import navElementButton from '../../navelementButton'
  import modalComponent from '../../wallet/modalComponent.vue'
  import profileStats from './profileStats.vue'  
  export default {
    name: 'profileComponent',
    components: {
      navElementButton,
      modalComponent,
      profileStats
    },
    data() {
      return {
        isMainActive: false,
        isCryptoActive: false,
        isRBXActive: false,
        balance: 0
      };
      
    },
    methods: {
      switchWallet() {
      this.isMainActive = !this.isMainActive
      console.log(this.isMainActive)
    },
    openSubmodalRbx() {
          this.isMainActive = !this.isMainActive
          this.isRBXActive = !this.isRBXActive
          this.isCryptoActive = false
          console.log(this.isMainActive)
      },
    openSubmodalCrypto() {
          this.isMainActive = !this.isMainActive
          this.isRBXActive = false
          this.isCryptoActive = !this.isCryptoActive
      },
    getImage(img) {
      return require(`@/assets/${img}`)
    }
    },
    
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
  /* Your component-specific styles go here */
  .profile {
    display: flex;
  }

  .settings-button {
    margin: auto 1;
  }  .wallet-button {
    margin: auto 0;
  }

  .balance {
    margin: 0 16px 0 12px;
  }

  #balance-text {
    font-family: 'Rubik', sans-serif;
    text-shadow: #C02942 0 0 5px;

    color: #C02942;
    font-weight: 800;
    color: #C02942;
    
    
  }
  
  

  .stat-text {
    font-size: 16px;

  }

  .stat-text > span {
    margin: auto 0;
  }

  .profile > img {
    width: 36px;
    height: 36px;
    margin: auto 0px;
  }

  .buttons {
    display: flex;

  }

  .buttons > * {
    margin: 0 5px;
  }
  
  </style>
  