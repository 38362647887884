<!-- App.vue -->

<template>
  <div id="app">
      <settingsContent />
      <router-view />
    </div>
</template>

<script>
import settingsContent from '../components/settings/settingsContent.vue';

  export default {
    name: 'settingsPage',
    components: {
      settingsContent,
    }
  }
  </script>
  
  <style>
  /* Add your global styling here */
  @import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap');
  
  
  html {
    width: 100%;
    height: 100vh;
    background-color: #212121;;


  }
  
  body {
    margin: 0 8px;
  }


  </style>
  