<template>
  <div>
    <div id="noticeText">
        <p>Notices</p>

      </div>
    </div>
</template>

  
  <script>

  export default {
    name: 'noticesComponent',
    data() {
      return {
      };
    },
    components: {

    },
    mounted() {
      this.fetchNotices()
    },
    methods: {
      async fetchNotices() {
    try {
      const response = await fetch('');
      const data = await response.json();
      const notices = data.notices;

      notices.forEach(notice => {
        const noticeText = notice.text;
        const noticeDate = notice.date;
        const noticeSeverity = notice.severity;

        const noticeParagraph = document.createElement('p');
        noticeParagraph.textContent = `${noticeText} - Date: ${noticeDate} - Severity: ${noticeSeverity}`;

        // Replace 'noticesContainer' with the id of the div where you want to append the paragraphs
        document.getElementById('noticeText').appendChild(noticeParagraph);
      });
    } catch (error) {
      console.error('Error fetching notices:', error);
    }
  }
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
<style scoped>


#noticeText {
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #333333;
  border-radius: 8px;
  text-align: center;
  padding-bottom: 20px; 
  padding-left: 20px;
  padding-right: 20px;
}

p1 {
  color: #C02942;
  font-size: 16px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  
}



#noticeText > p {
  border-radius: 8px;
}

#noticeText > :first-child {
  font-family: "Tektur", sans-serif;
  color: #C02942;
  font-size: 34px;
  transition: 0.2s ease;
  transform: scale(1);
  opacity: 1;
  text-shadow: #C02942 0 0 5px;
}

#noticeText > :first-child:hover {
  transition: 0.2s ease;
  transform: scale(1.02);
  opacity: 0.9;
}
</style>

