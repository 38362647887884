import { createApp } from 'vue';
import App from './App.vue';
import router from './router';




const app = createApp(App);

app.use(router);
app.mount('#app'); 


import WebSocketService from './gamblesocket';

const webSocketService = new WebSocketService('ws://3000-pelaajahacks-gamblesite-ecbq94cvad6.ws-eu111.gitpod.io//game');
webSocketService.onConnect(() => {
  const token = localStorage.getItem('token');
  webSocketService.authenticate(token, (success) => {
    if (success === false) {
      router.push("/login");
      console.log("Authentication failed");
    } else {
      const balance = webSocketService.getBalance();
      if (balance === null) {
        router.push("/login");
        console.log("Balance is null");
      } else {
        console.log("Balance:", balance);
        // Perform other actions based on the balance
      }
    }
  });
});

