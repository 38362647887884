
class WebSocketService {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.connectCallbacks = [];
    this.authenticationCallbacks = []; // Add callbacks for authentication
    this.balance = null; // Initialize balance property
    this.connect();
  }

  connect() {
    this.socket = new WebSocket(this.url);
    this.socket.addEventListener('open', () => {
      console.log('WebSocket connected');
      this.executeConnectCallbacks();
    });
    this.socket.addEventListener('message', (event) => {
      console.log('Message received:', event.data);
      const message = JSON.parse(event.data);
      if (message.action === 'authResult') {
        this.updateBalance(message.stats.balance); // Update balance when received from WebSocket
        this.handleAuthenticationResult(message.success);
      }
      if (message.action === 'balanceUpdate') {
        this.updateBalance(message.stats.balance); // Update balance when received from WebSocket
      }
    });
    this.socket.addEventListener('close', () => {
      console.log('WebSocket disconnected, attempting to reconnect...');
      setTimeout(() => this.connect(), 3000); // Reconnect after 3 seconds
    });
  }

  send(message) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket connection not open.');
    }
  }

  authenticate(token, callback) {
    this.authenticationCallbacks.push(callback); // Store callback for authentication result
    this.send(JSON.stringify({
      "action": "auth",
      "token": token
    }));
  }

  onConnect(callback) {
    this.connectCallbacks.push(callback);
  }

  executeConnectCallbacks() {
    this.connectCallbacks.forEach(callback => {
      callback();
    });
  }

  handleAuthenticationResult(success) {
    this.authenticationCallbacks.forEach(callback => {
      callback(success);
    });
  }

  updateBalance(newBalance) {
    this.balance = newBalance;
  }

  getBalance() {
    return this.balance;
  }
}

export default WebSocketService;
