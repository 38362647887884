<template>
    <button class="button" @click="handleClick">
        <span>{{ name }}</span>
        <div class="logo">
            <img :src="getImage(type)" :alt="type">
        </div>
        
    </button>
  </template>
  
  <script>
  export default {
    name: 'navElementButton',
    data() {
      return {
        // Your data properties go here
      };
    },
    props: {
        name: String,
        type: String
    },
    methods: {
      handleClick() {
        this.$emit("clicked")
        console.log("CLICKED")
      },
      getImage(type) {
        return require(`@/assets/${type}`)
      }
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
.button {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #333333;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
  transition: 0.4s ease-out;
  border-radius: 16px;

  &:hover {
    
    box-shadow: 6px 6px rgba(0, 0, 0, 0.6);
    opacity: 0.9;
    transform: scale(1.02);
    
  }
}

  .button > span {
    margin: 15px;
    margin-top: 0px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: beige;
  }
  .logo > img {
    width: 100px;
    filter: drop-shadow(5px 4px 5px #000);
    transition: 0.4s ease-out;
    &:hover {
        filter: drop-shadow(8px 7px 3px #111),

    }
  }

  .logo {
    justify-content: center;
    align-items: center;
  }
  </style>
  