<!--modalComponent.vue -->

<template>
  <transition name="fade">
    <div class="modal-overlay" v-if="isMainActive && activeTab === 'deposit'"></div>
  </transition>
  <transition name="slide">
  <div class="modal" v-if="isMainActive && activeTab === 'deposit'">
    <div id="option-selector">
      <h1 @click="handleClick('deposit')" :class="{ 'title': true, 'active': activeTab === 'deposit', 'clickable': activeTab !== 'deposit'}">Deposit</h1>
      <h1 class="title"> | </h1>
      <h1 @click="handleClick('withdraw')" :class="{ 'title': true, 'clickable': activeTab !== 'withdraw', 'active': activeTab === 'withdraw' }">Withdraw</h1>
    </div>
    
      <p>What Do You Wish To Deposit With?</p>
      <paymentComponent @rbx="openSubmodalRbx" @crypto="openSubmodalCrypto" />
      <button class="button" @click="switchModal">
        Close
      </button>
    </div>
  </transition>

  <transition name="fade">
    <div class="modal-overlay" v-if="isMainActive && activeTab === 'withdraw'"></div>
  </transition>
  <transition name="slide">
  <div class="modal" v-if="isMainActive && activeTab === 'withdraw'">
    <div id="option-selector">
      <h1 @click="handleClick('deposit')" :class="{ 'title': true, 'active': activeTab === 'deposit', 'clickable': activeTab !== 'deposit'}">Deposit</h1>
      <h1 class="title"> | </h1>
      <h1 @click="handleClick('withdraw')" :class="{ 'title': true, 'clickable': activeTab !== 'withdraw', 'active': activeTab === 'withdraw' }">Withdraw</h1>
    </div>
    
      <p>How much would you like to withdraw?</p>
      <inputBox :img="getImage('currency.png')" placeholder="Withdrawal amount" />
      <div class="buttons">
        <button class="with-button" @click="withdraw">
          Withdraw
        </button>
      </div>
      
      <button class="button" @click="switchModal">
        Close
      </button>
    </div>
  </transition>


  <transition name="fade">
    <div class="modal-overlay" v-if="isCryptoActive"></div>
  </transition>
  <transition name="slide">
  <div class="modal" v-if="isCryptoActive">
    <h1 class="title">Deposit monero</h1>
      <button class="button" @click="openSubmodalCrypto">
        Back
      </button>
    </div>
  </transition>

  <transition name="fade">
    <div class="modal-overlay" v-if="isRBXActive"></div>
  </transition>



  <transition name="slide">
  <div class="modal" v-if="isRBXActive">
    <h1 class="title">Deposit robux</h1>

      <button class="button" @click="openSubmodalRbx">
        Back
      </button>
    </div>
  </transition>
  </template>
  
  <script>
  import paymentComponent from './payments/paymentComponent.vue'
  import inputBox from '@/components/inputBox.vue'
  export default {
   
    name: "modalComponent",
    data() {
      return {
        activeTab: 'deposit',
        activeWithdrawal: true
      };
    },
    props: {
      isMainActive: Boolean,
      isRBXActive: Boolean,
      isCryptoActive: Boolean
    },
    methods: {
      switchModal() {
        this.$emit("switchModal")
        
      },
      openSubmodalRbx() {
          this.$emit("switchRbx")
      },
      openSubmodalCrypto() {
          this.$emit("switchCrypto")
      },
      handleClick(tab) {
      this.activeTab = tab;
    },
      withdraw(amount) {
        console.log(amount)
      },
      getImage(img) {
        return require(`@/assets/${img}`)
      }
    },
    components: {
      paymentComponent,
      inputBox
    }
  };
  </script>
  
  <style scoped>


  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    max-width: 400px;
    background-color: #232323;
    border-radius: 16px;
    
    padding: 25px;
  
  h1 {
    color: beige;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 15px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
  
  p {
    color: beige;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s linear;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateY(-30%) translateX(-50%);
    opacity: 0
  }
  .modal {
    display: flex;
    flex-direction: column;
  }
  .title {
    text-align: center;
  }

  .button {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    
    display: inline-block;
    padding: 10px 20px;
    margin-top: 32px;
    transition: 0.2s ease;

    border-radius: 8px;
    background-color: #C02942;
    color: #232323;
    font-size: 18px;
    font-weight: 700;
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    &:hover {
      box-shadow: #C02942 0 0 5px;

      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }
  }
  .with-button {
    
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    
    display: inline-block;
    width: 30%;
    
    padding: 7px 7px;
    margin-top: 10px;
    background-color: #C02942;
    transition: 0.2s ease;
  
    border-radius: 8px;
    
    color: #232323;
    font-size: 18px;
    font-weight: 700;
    
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    &:hover {
      box-shadow: #C02942 0 0 5px;

      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }
  }
 


  #option-selector {
    display: flex;
    margin: 0 auto;
    

  }
  #option-selector > h1 {
    margin: 0 5px;
  } 
  #option-selector > .active {
    color: #C02942;
    transition: 0.4s;
  }
  #option-selector > .clickable {
    opacity: 0.2;
    transition: 0.4s;
    cursor: pointer;
  }

  .buttons {
    display: flex;
  }
  </style>
  