<template>
    <div class="navElement">
        <button @click="handeClick">
          <span>{{ name }}</span>
        </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'navElementButton',
    data() {
      return {
        // Your data properties go here
      };
    },
    props: {
        name: String
    },
    methods: {
      handeClick() {
        this.$emit("button-click")
      }
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
  .navElement > button {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    
    display: inline-block;
    padding: 10px 12px;
    background-color: #C02942;
    border-radius: 8px;
    opacity: 0.85;
    
    color: #232323;
    font-size: 18px;
    font-weight: 700;
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    &:hover {
      box-shadow: #C02942 0 0 5px;

      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }
  }
  </style>
  