<template>
  <div class="container-outer">
    <div class="topbar">
        <navbarComponent />
      </div>
    <div class="container-inner">
      <sidebarComponent />
    <div class="content">
      <noticesComponent />
    </div>
    <div class="content-credits">
      <creditsComponent />
    </div>
    
  </div>
  </div>
  
    
  </template>
  
  <script>
  import sidebarComponent from '../../components/sidebarhome/sidebar.vue'
  import navbarComponent from '../../components/navbar/navbar.vue';
  import noticesComponent from './notices.vue'
  import creditsComponent from './credits.vue'

  export default {
    name: 'homeContent',
    data() {
      return {
      };
    },
    components: {
      sidebarComponent,
      navbarComponent,
      noticesComponent,
      creditsComponent

    },
    methods: {
      // Your methods go here
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>

  .content {
    display: flex;
    margin: 0 24px;
  }
  .content-credits {
    display: flex;
    margin: 0 24px;
  }

  .trendinggames {
    display: inline-flex;
    flex-direction: column;
    margin: 0 50px;
  }
  .topbar {
    position: sticky;
    top: 0;
    padding-bottom: 12px;
    
  }

  .container-inner {
    display: flex;
  }
  .container-outer {
    display: flex;
    flex-direction: column;
  }
  </style>
  