

<script>


export default {
  setup() {

  },
  props: {
    input_type: String
  },
  name: "input_box"
}
</script>

<style scoped>
.inputbox {
  height: 35px;
  border-radius: 10px;
  border: none;
  background-color: #232323;
  color: #C02942;
  font-size: 16px;
        text-shadow: #C02942 0 0 5px;
    outline: none;

}
</style>


<template>
    <input v-bind:type="input_type" class="inputbox">
</template>