<template>
    <div class="custom-input-container">
      <input type="text" class="custom-input" :placeholder="placeholder">
      <img :src="img" alt="Logo" class="logo">
    </div>
  </template>

<script>
export default {
  name: 'inputBox',
  data() {
    return {
      // Your data properties go here
    };
  },
  methods: {
    // Your methods go here
  },
  props: {
    img: String,
    placeholder: String
  }
  // Other component options (computed, watch, etc.) can be added here
};
</script>
  
  <style scoped>
  .custom-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .custom-input {
    width: 100%;
    padding: 15px;
    border: 2px solid #212121;
    color: beige;
    background-color: #212121;;
    border-radius: 15px;
    font-size: 16px;
    outline: none;
    text-shadow: #C02942 0 0 5px;

  }
  
  .logo {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 30px;

  }
  </style>
  