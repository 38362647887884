
<script>

export default {
  setup() {

  },
  props: {

  },
  data() {
    return {
      loginError: "",
      errorText: "😬Incorrect Password Knobhead☠️",
      password: "",
      username: ""
    }
  },
  methods: {
    login() {
      fetch('https://3000-pelaajahacks-gamblesite-ecbq94cvad6.ws-eu111.gitpod.io/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.username,
        password: this.password
      })
    })
    .then(response => {
      
      if (!response.ok) {
        console.log("stop fuckering")
      }
      return response.json();
    })
    .then(data => {
      // Save token to localStorage or sessionStorage
      if (data.loginerror) {
        this.loginError = data.loginerror
        this.errorText = data.errorText
      }
      else {
        localStorage.setItem('token', data.token);
        this.$router.push('/');
      }
      
    })
    }
  },
  components: {
  },
  name: "LoginPage"
}
</script>

<style scoped>
@font-face {
  font-family: 'GG Sans';
  src: url('../assets/fonts/ggsans/ggsans-Semibold.ttf') format('truetype');
}

.main {
  display: flex;
  height: 100vh;
  margin: 0px 3px;
}

.login-form {
  margin: auto auto;
  display: flex;
  flex-direction: column;
  background-color: #333333;;;
  padding: 20px;
  border-radius: 10px;

}

.inputs {
  height: 100%;
  /* Set the height of the .inputs element to 100% */
  text-align: center;
  margin: 0 20px;
}

.password {
  margin-top: 15px;
}

.box {
  width: max(100%, min(8vmax, 50px));

}

.inputbox {
  width: 100%;
}
@keyframes pulse {
  0% {
    transform:scale(1.2);
    opacity: 1;

  }
  50% {
    transform:scale(1.3);
    text-shadow: #C02942 0 0 5px;
  }
  100% {
    transform:scale(1.2);
    opacity: 1;
  } 
}

.title-text {
  animation: pulse 4s infinite;
  font-size: max(25px, min(8vmax, 50px));
  margin-bottom: 4vh;
  text-align: center;
  font-family: "Tektur", sans-serif;
  color: #C02942;
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    text-shadow: #C02942 0 0 5px;
  cursor: pointer;
    text-decoration: none;
  a:link, a:visited, a:active {
  color: #C02942;
  text-decoration: none;
}
  &:hover {
      text-shadow: 0 0 10px #C02942, 0 0 20px #C02942,, 0 0 30px #C02942,, 0 0 40px #C02942,;
  }
}

.login-text {
  text-align: left;
  color: #C02942;
  font-family: GG Sans;
  margin-bottom: 3px;
  font-size: 20px;
        text-shadow: #C02942 0 0 5px;


}

.red {
  color: #e71b2c;
}

.signup {
  margin-top: 7px;
  font-family: GG Sans;
  font-size: 20px;
  color: #C02942;
  text-decoration: none;

    text-shadow: #C02942 0 0 5px;

}

.login-button {
  text-align: right;
  flex: 1 0 0;
  margin-left: 15px;
}
p { 
  margin-top: 7px;
  font-family: GG Sans;
  font-size: 15px;
  color: #C02942;
  text-decoration: none;
margin-top: 1px;
    text-shadow: #C02942 0 0 5px;}
#login-btn {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: none;
  background-color: #C02942;
  color: #333333;
  cursor: pointer;
  font-family: GG Sans;
  font-size: 18px;
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    &:hover {
      box-shadow: #C02942 0 0 5px;

      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }
}

#login-btn:active {
  opacity: 0.6;
}
p{
  margin-top: 1;

}
.login-footer {
  display: flex;
  margin: 10px 20px 0 20px;
}

.inputbox {
  height: 35px;
  border-radius: 10px;
  border: none;
  background-color: #232323;
  color: #C02942;
  font-size: 16px;
        text-shadow: #C02942 0 0 5px;
    outline: none;

}
</style>


<template>
  <main>
    <div class="main">
      <div class="login-form">
        <div class="title-text">
          <RouterLink to="/about">Horizn</RouterLink>
        </div>
        <div class="inputs">
          <div class="box">
            <div class="username login-text">Username <span class="red">*</span></div>
            <input class="inputbox" v-model="username">
          </div>

          <div class="box">
            <div class="password login-text">Password <span class="red">*</span></div>
            <input type="password" class="inputbox" v-model="password">
            <p v-if="loginError">{{ errorText }}</p>

          </div>

        </div>
        <div class="login-footer">
          <div class="signup">
            <RouterLink to="/register" class="signup">Sign Up!</RouterLink>
          </div>

          <div class="login-button">
              <button id="login-btn" @click="login">Log In</button>
            </div>
        </div>
        

        

      </div>
    </div>


  </main>
</template>