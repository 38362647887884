<!-- Navbar.vue -->

<template>
    <div class="bar">
        <div class="sidebar">
                <gameLink icon="crash-sidebar.png" game="Crash" gameLink="/crash" :class="{ 'active': this.$route.path === '/crash' }" />
                <gameLink icon="slide-sidebar.png" game="Slide" gameLink="/slide" :class="{ 'active': this.$route.path === '/slide' }"/>
      </div>

    </div>
      
  </template>
  
  <script>

  import gameLink from './gameLink.vue'
  export default {
    name: 'sidebarComponent',
    components: {
      gameLink
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    // Use a computed property to access the current route path
    currentRoutePath() {
      return this.$route.path;
    }
}}
  
  </script>
  
  <style scoped>
  /* Add your styling here */


  .sidebar {
    background-color: #2f3136;;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    color: #5f6892;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    position: sticky;
    top: 67px;
  }


  .active {
    color: #a5b5fc;
  }
  .sidebar > * > * {
    margin: 3px 0px;
  }


  </style>
  