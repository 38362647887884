<template>
    <div class = "profile">

      <div class="userprofile">
        <div class="user">
          <img src="@/assets/gameIcon/fireinthehole.png" alt="">
          <div class="userstats">
            <span class="username">{{ username }}</span>
            <span class="stats">Joined 22.04.08</span>
            <span class="stats">UID: 22040823</span>
          </div>
        </div>
        

        <div class="statistics">
          <settingsProfileStat title="Wagered" :stat="wagered.toLocaleString()" :img="getImage('rbx.svg')" />
          <settingsProfileStat title="Deposited" :stat="deposited.toLocaleString()" :img="getImage('rbx.svg')" />
          <settingsProfileStat title="Withdrewn" :stat="withdrewn.toLocaleString()" :img="getImage('rbx.svg')" />
        </div>
        
      </div>
        
    </div>
  </template>
  
  <script>

  import settingsProfileStat from './settingsProfileStat.vue'

  export default {
    name: 'profileComponent',
    data() {
      return {
        username: "ME",
        wagered: 20421,
        deposited: 340394211,
        withdrewn: 43251
      };
    },
    methods: {
      getImage(img) {
        return require(`@/assets/${img}`)
      }
    },
    components: {
      settingsProfileStat,
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
  .profile{
    background-color: #333333;;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    color: #C02942;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    margin: 0 auto;
    color: #C02942;
    font-size: 20px;
    padding: 16px;
  }

  .user {
    display: flex;
  }

  .user > img {
    width: 64px;
    height: 64px;
  }

  .userstats {
    margin: auto 8px;
    display: flex;
    flex-direction: column;
  }

  .stats {
    color: #C02942;
    font-size: 16px;
  }

  .statistics {
    display: flex;
    flex-direction: column;

  }

  .statistics > * {
      margin-top: 24px;
  }


  </style>
  