<!-- Navbar.vue -->

<template>
    <div class="bar">
        <div class="sidebar">
                <gameLink icon="crash-sidebar.png" game="News" gameLink="/" :class="{ 'active': this.$route.path === '/' }" />
                <gameLink icon="slide-sidebar.png" game="Unblocker" gameLink="/unblocker" :class="{ 'active': this.$route.path === '/slide' }"/>
                <gameLink icon="slide-sidebar.png" game="VPN" gameLink="/vpn" :class="{ 'active': this.$route.path === '/slide' }"/>
                <gameLink icon="slide-sidebar.png" game="AI" gameLink="/ai" :class="{ 'active': this.$route.path === '/slide' }"/>
                <gameLink icon="slide-sidebar.png" game="Minecraft" gameLink="/minecraft" :class="{ 'active': this.$route.path === '/slide' }"/>






      </div>

    </div>
      
  </template>
  
  <script>

  import gameLink from './gameLink.vue'
  export default {
    name: 'sidebarComponent',
    components: {
      gameLink
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    // Use a computed property to access the current route path
    currentRoutePath() {
      return this.$route.path;
    }
}}
  
  </script>
  
  <style scoped>
  /* Add your styling here */


  .sidebar {
    background-color: #333333;;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    color: #C02942;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    position: sticky;
    top: 67px;
    font-weight: 200;
    
  }


  .active {
    color: #C02942;
    text-shadow: #C02942 0 0 5px;
 
  }
  .sidebar > * > * {
    margin: 3px 0px;

  }


  </style>
  