<!-- Navbar.vue -->

<template>
    <div class="navbar">
      <div id="logo">
        <p>Horizn</p>
      </div>
      <div class="links">
        <navElementLink :class="{ 'active': this.$route.path === '/' }" class="HomeButton" name="Home" linkTo="/" />
        <navElementLink :class="{ 'active': this.$route.path === '/shop' }" class="ShopButton" name="Shop" linkTo="/shop" />

        <navElementLink :class="{ 'active': this.$route.path === '/gamble' }" class="GambleButton" name="Gamble" linkTo="/gamble" />
 

      </div>
      
      <profileComponent class="profile" />
      
    </div>
  </template>
  
  <script>
  import navElementLink from '../navelementLink'
  import profileComponent from './profile/profileComponent.vue'
  export default {
    name: 'navbarComponent',
    components: {
    navElementLink,
    profileComponent
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    // Use a computed property to access the current route path
    currentRoutePath() {
      return this.$route.path;
    }
}}
  
  </script>
  
  <style scoped>
  /* Add your styling here */

  .navbar {
    display: inline-flex;
    margin: 0px 10px;
    width: 100%;
    box-sizing: border-box;
    
  
  }
  .navElement {
    padding: 5px min(2vw, 50px);
    color: #C02942; 
    &:hover {
      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
      text-shadow: #C02942 0 0 5px;

    }    
  }

  .profile {
      margin-left: auto;
      margin-right: 10px;
  }
    
  #logo {
    
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
  }
  #logo > p {
    margin: 0;
    text-align: center;;
    
  }

  #logo > :first-child {
    font-family: "Tektur", sans-serif;
    color: #C02942;
    text-shadow: #C02942 0 0 5px;
    &:hover {
      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }    
    font-size: 40px;
  }


  .links {
    display: flex;

  }


  .active {
    color: #C02942;
    font-weight: 800;
    font-weight: bold;
    color: #C02942;
    
    text-shadow: #C02942 0 0 5px;
    &:hover {
      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }    
  }

  </style>
  