<template>
  <div>
    <div id="noticeText">
        <p>Credits</p>
        <span style="color: red;">Creator of</span> <span style="color: blue;"><a href="https://cybersaber.dev">cybersaber.dev</a></span>        <p2>Creator Of segso.net | hsl.wiki</p2>

      </div>
    </div>
</template>

  
  <script>

  export default {
    name: 'creditsComponent',
    data() {
      return {
      };
    },
    components: {

    },
    methods: {
      // Your methods go here
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
<style scoped>


#noticeText {
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #333333;
  border-radius: 8px;
  text-align: center;
  padding-bottom: 20px; 
  padding-left: 20px;
  padding-right: 20px;
}

p1 {
  color: #C02942;
  text-shadow: #C02942 0 0 5px;

  font-size: 16px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  
}
p2 {
  text-shadow: #2c1ef3 0 0 5px;

  color: #2c1ef3;
  font-size: 16px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  
}



#noticeText > p {
  border-radius: 8px;
}

#noticeText > :first-child {
  font-family: "Tektur", sans-serif;
  color: #C02942;
  font-size: 34px;
  transition: 0.2s ease;
  transform: scale(1);
  opacity: 1;
  text-shadow: #C02942 0 0 5px;
}

#noticeText > :first-child:hover {
  transition: 0.2s ease;
  transform: scale(1.02);
  opacity: 0.9;
}
</style>

