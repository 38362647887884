<template>
    <div class="buttons">
        <paymentButton @clicked="handleClickCash" class="button" type="crypto.png" name="Cash"/>
        <paymentButton @clicked="handleClickStripe" class="button" type="crypto.png" name="Stripe"/>

    </div>
    
</template>
  
<script>
    import paymentButton from './paymentButton.vue'
    export default {
        name: 'paymentComponent',
        components: {
            paymentButton
        },
        data() {
        return {
            // Your data properties go here
        };
        },
        props: {
            name: String
        },
        methods: {
            handleClickCash() {
                this.$emit("Cash")
            },
            handleClickStripe() {
                this.$emit("Card")
            }
            
        },
        // Other component options (computed, watch, etc.) can be added here
    };
</script>
  
<style scoped>
  .buttons {
    display: flex;
  }

  .button {
    width: 100%;
    aspect-ratio: 1/1;
    margin: 10px;
  }

  

</style>
  