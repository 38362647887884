<template>
    <div class="navElement">
        <router-link :to="linkTo">
            <span class="navText">{{ name }}</span>
        </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'navElementLink',
    data() {
      return {
        // Your data properties go here
      };
    },
    props: {
        linkTo: String,
        name: String,
    },
    methods: {
      // Your methods go here
    },
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
    a {
      font-family: 'Montserrat', sans-serif;
      text-decoration: none;
      color: inherit;
      transition: color 0.4s ease, opacity 0.4s ease; /* Combine transition properties */
      opacity: 0.8;
      transition: opacity 0.4s ease;
      font-weight: 500;
      &:hover {
        opacity: 1;
        transition: opacity 0.4s ease;
      }
    }
    a:visited {
      color: inherit;
      transition: color 0.4s ease;
    }

    .navText {
      padding-top: 5px;
    }
    .active {
      border-top: 2px solid #C02942;
      
    }
  </style>
  