import { createRouter, createWebHistory } from 'vue-router'
import GambleView from '../views/gamble.vue'
import HomeView from '../views/home.vue'
import settingsPage from '../views/settingsPage.vue'
import ShopView from '../views/shop.vue'
import LoginPage from '../views/Login-Page.vue'
import SignupPage from '../views/Signup-Page.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gamble',
    name: 'gamble',
    component: GambleView
  },
  {
    path: '/settings',
    name: 'settings',
    component: settingsPage
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView
  },
  {
    path: '/login',
    name: 'log',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'reg',
    component: SignupPage
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
