<template>
    <div class="stat">
        <span>{{ stat }}</span>
        <img v-if="img" :src="img" alt="">
    </div>
  </template>
  
  <script>
  export default {
    name: 'profileStats',
    data() {
      return {
        // Your data properties go here
      };
    },
    methods: {
      // Your methods go here
    },
    props: {
        stat: String,
        img: String
    }
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
    .stat {
      font-family: 'Rubik', sans-serif;
    text-shadow: #C02942 0 0 5px;

    color: #C02942;
    font-weight: 800;
    color: #C02942;
    align-self: center;
    
        
    }

    .stat > span {
      margin: auto;
    }

    .stat > img {
      width: 32px;
      height: 32px;
      margin: auto 0 auto 9px;
      margin-left: 10px;
      
    }

    
  </style>
  