
<script>
import input_box from '../components/login/input-box.vue'


export default {
  setup() {

  },
  props: {
    
  },
  components: {
    input_box
  },
  name: "SignupPage",
  methods: {
   
  },

  data() {
    return {

    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'GG Sans';
  src: url('../assets/fonts/ggsans/ggsans-Semibold.ttf') format('truetype');
}

.main {
  display: flex;
  height: 100vh;
  margin: 0px 3px;
}
.login-form {
  margin: auto auto;
  display: flex;
  flex-direction: column;
  background-color: #333333;;;
  padding: 20px;
  border-radius: 10px;

}
.inputs {
  height: 100%; /* Set the height of the .inputs element to 100% */
  text-align: center;
  margin: 0 20px;
}

.not-last-child {
  margin-bottom: 15px;
}

.box {
  width: max(100%, min(8vmax, 50px));
  
}

.inputbox {
  width: 100%;
}
@keyframes pulse {
  0% {
    transform:scale(1.2);
    opacity: 1;

  }
  50% {
    transform:scale(1.3);
    text-shadow: #C02942 0 0 5px;
  }
  100% {
    transform:scale(1.2);
    opacity: 1;
  } 
}

.title-text {
  animation: pulse 4s infinite;
  font-size: max(25px, min(8vmax, 50px));
  margin-bottom: 4vh;
  text-align: center;
  font-family: "Tektur", sans-serif;
  color: #C02942;
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    text-shadow: #C02942 0 0 5px;
  cursor: pointer;
    text-decoration: none;
  a:link, a:visited, a:active {
  color: #C02942;
  text-decoration: none;
}
  &:hover {
      text-shadow: 0 0 10px #C02942, 0 0 20px #C02942,, 0 0 30px #C02942,, 0 0 40px #C02942,;
  }
}


.login-text {
  text-align: left;
  color: #C02942;
  font-family: GG Sans;
  margin-bottom: 3px;
  font-size: 20px;
        
    text-shadow: #C02942 0 0 5px;

}

.red {
  color: #e71b2c;
}

.login {
  margin-top: 7px;
  font-family: GG Sans;
  font-size: 20px;
  color: #C02942;
  text-decoration: none;

    text-shadow: #C02942 0 0 5px;

}

.signup-button {
  text-align: right;
  flex: 1 0 0;
  margin-left: 15px;
}



#signup-btn {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: none;
  background-color: #C02942;
  color: #333333;
  cursor: pointer;
  font-family: GG Sans;
  font-size: 18px;
    transition: 0.2s ease;
    transform: scale(1);
    opacity: 1;
    &:hover {
      box-shadow: #C02942 0 0 5px;

      transition: 0.2s ease;
      transform: scale(1.02);
      opacity: 0.9;
    }
}

#signup-btn:active {
  opacity: 0.6;
}

.signup-footer {
  display: flex;
  margin: 10px 20px 0 20px;
}

</style>


<template>
  <main>
    <div class="main">
      <div class="login-form">
        <div class="title-text">
          <RouterLink to="/about">Horizn</RouterLink>
        </div>
        <div class="inputs">
          
          <div class="box">
            <div class="username login-text">Username <span class="red">*</span></div>
            <input_box input_type="text" class="inputbox not-last-child" v-model="username"></input_box>
          </div>
          
          <div class="box">
            <div class="password login-text">Password <span class="red">*</span></div>
            <input_box input_type="password" class="inputbox not-last-child" v-model="password"></input_box>
          </div>

          

          <div class="box">
            <div class="invite login-text">License Key <span class="red" >*</span></div>
            <input_box input_type="text" class="inputbox" v-model="invite"></input_box>
          </div>
          
        </div>

        <div class="signup-footer">
          <div class="login">
            <RouterLink to="/login" class="login">Log In!</RouterLink>
          </div>

          <div class="signup-button">
              <button id="signup-btn" >Sign Up</button>
            </div>
        </div>
        
      </div>
    </div>
    
    
  </main>
  
  
</template>