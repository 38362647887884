<template>
    <div class="game" :style="{ backgroundImage: `url(${img})` }">
      <div class="gameContent">
         <span>{{ gameName }}</span>
      </div>
       
        
    </div>
  </template>
  
  <script>
  export default {
    name: 'gameComponent',
    data() {
      return {
        // Your data properties go here
      };
    },
    methods: {
      // Your methods go here
    },
    props: {
        gameName: String,
        gameLink: String,
        img: String
    }
    // Other component options (computed, watch, etc.) can be added here
  };
  </script>
  
  <style scoped>
    .game {
        display: flex;
        flex-direction: column;

        text-align: center;
        font-family: 'Montserrat', sans-serif;
        width: 180px;
        height: 230px;
        box-shadow: 4px 4px 12px #111;
        transition: 0.4s ease;
        &:hover {
          box-shadow: 2px 2px 30px 1px #610894;
          transition: 0.4s ease;
          cursor: pointer;
        }
    }

    .gameContent > span {
        font-family: 'Montserrat', sans-serif;
        margin: auto auto 0 auto;
        color: #f0f5ff;
        font-size: 32px;
        font-weight: 800;
    }

    .gameContent {
      height: 100%;
      margin: 16px;
      display: flex;
    }


  </style>
  